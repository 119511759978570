import { useLocation, useNavigate } from '@reach/router';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';

import type { JobSearchQueryInput } from '@xing-com/crate-common-graphql-types';
import { useSearchParameter } from '@xing-com/crate-hooks-use-search-parameter';
import { ActionButtons } from '@xing-com/crate-jobs-components-job-teaser-options';
import {
  JDP_JOB_SLUGS,
  JDP_SA_SEARCH_QUERY,
  JDP_BACK_PARAMS,
  SMALL_SCREEN_BOTTOM_SHEET_PARAM,
} from '@xing-com/crate-jobs-constants';
import { selectJobData } from '@xing-com/crate-jobs-helpers';
import type { JobRecommendationVisibleJob } from '@xing-com/crate-jobs-hooks';
import {
  useIsAiSearch,
  useJobBookmarkToggle,
  useSearchState,
} from '@xing-com/crate-jobs-hooks';
import { ROUTES } from '@xing-com/crate-jobs-paths';
import {
  getMatchingHighlightsFlags,
  InViewTracking,
  useJobsNwtTracking,
} from '@xing-com/crate-jobs-tracking';
import { JobTeaserListItem } from '@xing-com/crate-jobs-xinglets';
import { useExperiment } from '@xing-com/hub';
import { mediaConfined, mediaWideNavless } from '@xing-com/layout-tokens';
import {
  cornerRadiusXL,
  motionEasingStandard,
  motionTimeS,
  spaceL,
  spaceM,
  xdlColorBackgroundSecondary,
} from '@xing-com/tokens';

import type { JobItemResultVisibleJob, MatchingReasons } from '../../helpers';
import { useJDPUrlParams } from '../../hooks';

import type { ListType } from './results-list';

const appendMatchingReasonsFlag = (index: number, flags?: string): string =>
  `${flags ? `${flags},` : ''}matching_reasons_${index}`;

type Props = {
  item: JobItemResultVisibleJob | JobRecommendationVisibleJob;
  type: ListType;
  isNew: boolean;
  slugList: string[];
  searchQuery: JobSearchQueryInput;
  matchingReasons?: MatchingReasons;
};

export const Result: React.FC<Props> = ({
  item,
  type,
  isNew,
  slugList,
  searchQuery,
  matchingReasons,
}) => {
  const isAiSearch = useIsAiSearch();
  const nwtTrack = useJobsNwtTracking();
  const isSearchItem = item.__typename === 'JobItemResult';
  const urlParams = useJDPUrlParams(type);
  const location = useLocation();
  const navigate = useNavigate();

  const { getSearchParameter } = useSearchParameter();
  const isFilterView = !!getSearchParameter(SMALL_SCREEN_BOTTOM_SHEET_PARAM);

  const isRefreshExperimentActive = useExperiment('ABACUS-483') === 'B';
  const { sort } = useSearchState();
  const showRefreshedDate = isRefreshExperimentActive && sort === 'date';

  const job = isSearchItem
    ? item.jobDetail
    : // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      (item as JobRecommendationVisibleJob).job;
  const highlight = isSearchItem ? item.descriptionHighlight : undefined;
  const { isBookmarked } = selectJobData(job);

  const path = generatePath(ROUTES.jobDetails, {
    slug: job.slug,
  });
  const urlWithParams = `${path}${urlParams}`;
  const routerState = {
    [JDP_SA_SEARCH_QUERY]: isAiSearch ? null : searchQuery,
    [JDP_JOB_SLUGS]: {
      list: slugList,
      type: 'search',
      index: item.position,
    },
    [JDP_BACK_PARAMS]: location.search,
  };

  const matchingHighlightsFlags = getMatchingHighlightsFlags(
    item.matchingHighlightsV2
  );
  const nwtTrackingOptions = {
    page: 'jobs_search/serp',
    eventSchema: 'searchresult',
    position: item.position,
    itemUrn: job.globalId,
    trackingToken: item.trackingToken,
    flags: matchingReasons
      ? appendMatchingReasonsFlag(
          matchingReasons.index,
          matchingHighlightsFlags
        )
      : matchingHighlightsFlags,
  } as const;

  const onBookmark = useJobBookmarkToggle({
    jobId: job.id,
    isBookmarked,
    nwtTrackingOptions,
  });

  const trackOnClick = (): void => {
    nwtTrack({ ...nwtTrackingOptions, event: 'opened' });
  };

  const onClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();
    trackOnClick();

    if (!e.metaKey && !e.ctrlKey) {
      navigate(urlWithParams, { state: routerState });
    }
  };

  return (
    <li>
      <InViewTracking
        nwtTrackingOptions={{
          ...nwtTrackingOptions,
          event: 'entered_viewport',
        }}
        skip={isFilterView}
      >
        <Item
          job={job}
          url={urlWithParams}
          highlight={highlight}
          matchingHighlights={item.matchingHighlightsV2}
          isNew={isNew}
          onClick={onClick}
          onContextMenu={trackOnClick}
          onAuxClick={trackOnClick}
          showRefreshedDate={showRefreshedDate}
          data-testid="job-search-result"
          headingLevel="h2"
          aria-label={job.title}
          matchingReasons={matchingReasons}
          actions={
            <>
              <DesktopActionButtons
                iconsAfter
                isBookmarked={isBookmarked}
                onBookmark={onBookmark}
              />
              <MobileActionButtons
                iconsOnly
                isBookmarked={isBookmarked}
                onBookmark={onBookmark}
              />
            </>
          }
        />
      </InViewTracking>
    </li>
  );
};

const Item = styled(JobTeaserListItem)`
  & > article {
    padding: ${spaceM} 0;

    @media ${mediaWideNavless} {
      padding: ${spaceL} ${spaceM};
      transition: background-color ${motionTimeS} ${motionEasingStandard};

      &:hover {
        border-radius: ${cornerRadiusXL};
        background-color: ${xdlColorBackgroundSecondary};
      }
    }
  }
`;

const MobileActionButtons = styled(ActionButtons)`
  transform: translateY(-${spaceM});

  @media ${mediaConfined} {
    display: none;
  }
`;

const DesktopActionButtons = styled(ActionButtons)`
  display: none;

  @media ${mediaConfined} {
    display: flex;
    flex-shrink: 0;
    margin-left: ${spaceL};
    transform: translateY(-${spaceM});
  }
`;
