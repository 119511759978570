import type { AdFields } from '../../types';
import { SiteSections } from '../../types';

import * as BirthdaysPageAds from './birthdays';
import * as DiscoUpdatesAds from './disco-updates';
import * as JobsInlineAds from './jobs-inline';
import * as JobsJobListAds from './jobs-job-list';
import * as JobsRecommendationAds from './jobs-recommendation';
import * as JobsRecommendationMyJobsAds from './jobs-recommendation-my-jobs';
import * as JobsSearchAds from './jobs-search';
import * as LogoutPageAds from './logout';
import * as NotificationCenterAds from './notification-center';
import * as ProfilePageAds from './profile';
import * as SearchAds from './search';
import * as SupiNetworkHomeAds from './supi-network-home';

interface AdsBySection {
  [key: string]: Record<string, React.LazyExoticComponent<React.FC<AdFields>>>;
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const ads = {
  [SiteSections.DiscoUpdates]: DiscoUpdatesAds,
  [SiteSections.SupiNetworkHome]: SupiNetworkHomeAds,
  [SiteSections.JobsInline]: JobsInlineAds,
  [SiteSections.JobsJobList]: JobsJobListAds,
  [SiteSections.JobsRecommendation]: JobsRecommendationAds,
  [SiteSections.JobsRecommendationMyJobs]: JobsRecommendationMyJobsAds,
  [SiteSections.JobsSearch]: JobsSearchAds,
  [SiteSections.Birthdays]: BirthdaysPageAds,
  [SiteSections.Profile]: ProfilePageAds,
  [SiteSections.Logout]: LogoutPageAds,
  [SiteSections.NotificationCenter]: NotificationCenterAds,
  [SiteSections.Search]: SearchAds,
} as AdsBySection;

export default ads;
