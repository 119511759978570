import styled from 'styled-components';

import { Observed as BaseObserved } from '@xing-com/crate-communication-in-view';
import { BackgroundSection, Container } from '@xing-com/layout-primitives';
import {
  mediaConfined,
  mediaTopBarVisible,
  mediaWideNavless,
} from '@xing-com/layout-tokens';
import {
  spaceM,
  spaceXL,
  spaceXXL,
  space4XL,
  space6XL,
  spaceL,
} from '@xing-com/tokens';

export const Background = styled(BackgroundSection)`
  padding: 0;
`;

export const SearchBarContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: ${spaceM};

  @media ${mediaWideNavless} {
    padding-top: ${spaceXL};
    padding-bottom: 55px;
  }
`;

export const Observed = styled(BaseObserved)`
  margin-top: -43px;
  margin-bottom: 43px;

  @media ${mediaWideNavless} {
    margin-top: -${spaceM};
    margin-bottom: ${spaceM};
  }
`;

export const MainContainer = styled(Container)`
  display: block;
  margin-top: ${spaceXL};
  padding: 0 20px;

  @media ${mediaConfined} {
    padding: 0 ${spaceXXL};
  }
`;

export const SerpsContainer = styled(Container)`
  margin: ${space4XL} auto;
  padding: 0 20px;

  @media ${mediaConfined} {
    padding: 0 ${spaceXXL};
  }
`;

export const ContentContainer = styled(MainContainer)<{ $shrink: boolean }>`
  position: relative;
  margin-top: ${({ $shrink }) => ($shrink ? spaceM : spaceL)};

  @media ${mediaWideNavless} {
    display: grid;
    margin-top: ${spaceXXL};
    gap: ${spaceXXL};
    /* grid-template-columns: minmax(270px, 400px) minmax(600px, 800px); */ // Previous, check with Silke
    grid-template-columns: minmax(270px, calc(400 / 1256 * 100%)) minmax(
        300px,
        65.6%
      );
  }
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaceXXL};
  margin-right: ${spaceXXL};

  @media ${mediaTopBarVisible} {
    display: none;
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${space4XL};
  margin-bottom: ${space6XL};
  padding: 0 20px;
`;
